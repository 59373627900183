<template>
  <div
    style="
      scroll-snap-type: y proximity;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    "
    class="flex flex-col h-screen"
  >
    <div>
      <header class="flex h-screen">
        <div class="m-auto">
          <Brand />

          <ul class="flex justify-between mx-12 my-4 text-3xl text-gray-500">
            <li class="hover:text-gray-600">
              <a href="https://github.com/leonhusmann"
                ><font-awesome-icon :icon="['fab', 'github']"
              /></a>
            </li>
            <li class="hover:text-gray-600">
              <a href="https://de.linkedin.com/in/leon-husmann"
                ><font-awesome-icon :icon="['fab', 'linkedin']"
              /></a>
            </li>
            <li class="hover:text-gray-600">
              <a href="https://www.xing.com/profile/Leon_Husmann"
                ><font-awesome-icon :icon="['fab', 'xing']"
              /></a>
            </li>
            <li class="hover:text-gray-600">
              <a
                href="mailto:&#104;&#101;&#108;&#108;&#111;&#064;&#108;&#101;&#111;&#110;&#104;&#117;&#115;&#109;&#097;&#110;&#110;&#046;&#099;&#111;&#109;"
                ><font-awesome-icon :icon="['fas', 'envelope']"
              /></a>
            </li>
          </ul>
        </div>
      </header>
    </div>

    <div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Brand from "../components/Brand.vue";
import Footer from "../components/Footer.vue";

export default {
  components: {
    Brand,
    Footer,
  },
};
</script>

import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/legal",
    name: "Legal notice",
    component: () => import("../views/Legal.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy policy",
    component: () => import("../views/Privacy.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

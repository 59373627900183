<template>
  <footer class="flex flex-col m-4">
    <p class="mx-auto text-sm text-gray-500">
      &copy; {{ new Date().getFullYear() }} Leon Husmann
    </p>
    <p class="mx-auto text-sm text-gray-400">
      <router-link to="/legal">Legal notice</router-link> |
      <router-link to="/privacy">Privacy policy</router-link>
    </p>
  </footer>
</template>
